<template>
    <vx-card :title="'View Completed Stock Relocation Items'">
        <div class="vx-row mb-6">
            <vs-button
                class="ml-4 mt-2"
                color="danger"
                icon-pack="feather"
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Details</h4>
                <hr>
                <br>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Handover Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="handoverCode" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>STO Type</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="selectedType" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Source Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.selectedSrcWarehouse ? this.selectedSrcWarehouse.label : ''" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Dest. Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.selectedDestWarehouse ? this.selectedDestWarehouse.label : ''" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Dest. Address</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea :value="this.selectedDestWarehouse ? this.selectedDestWarehouse.address : ''" :readonly="true" class="w-full"></vs-textarea>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Imposition</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.selectedImposition ? this.selectedImposition.label : ''" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Driver (License Number - Name)</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.driver" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Vehicle</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.vehicle" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Req. Delivery Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.reqDeliveryDate" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Est. Time Arrived</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.eta" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Handover Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.handoverDate" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Estimate Shipment Cost</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input type="text" :value="shipmentCost" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Cost Ratio (%)</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="costRatio" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Notes</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea v-model="notes" width="100%" :readonly="true" />
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Attachments</h4>
                    <hr>
                    <br>
                    <div class="vx-row mb-3 mt-6" style="margin-left: 0%">
                        <table class="vs-table vs-table--tbody-table">
                            <template>
                                <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile" v-for="(tr, i) in fileAttachment">
                                    <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                    <td>
                                        <img :src="urlFile(tr)" style="width: 100px; height: 100px; object-fit: cover;" class="max-w-sm" @click="openPreview(i)" alt="">
                                    </td>
                                    <td class="td vs-table--td">
                                        <vx-tooltip text="Download" v-if="tr.PathFile != ''">
                                            <vs-button 
                                                type="line" 
                                                icon-pack="feather" 
                                                icon="icon-download" 
                                                :disabled="!tr.PathFile"
                                                @click.stop="download(tr)"
                                            />
                                        </vx-tooltip>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    <vue-easy-lightbox
                        :visible="visible"
                        :imgs="images"
                        :index="index"
                        @hide="handleHide"
                    />
                    <br>
                    <h4>Approval Matrix</h4>
                    <hr>
                    <vs-divider style="margin-left: 0%">
                        Source Warehouse Approval Status
                    </vs-divider>
                    <div class="vx-row mb-6">
                        <table class="m-3 vs-table vs-table--tbody-table table-bordered" width="100%">
                            <thead class="vs-table--thead">
                                <tr>
                                    <th>Approver</th>
                                    <th>Status</th>
                                    <th>Inbox Time</th>
                                    <th>Action Time</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tr, index) in srcApproverLines" :key="index" style="padding-bottom: 5px">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.approver }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.status }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.inbox_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.action_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.reason }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br>
                    <vs-divider style="margin-left: 0%" v-if="destApproverLines.length">
                        Destination Warehouse Approval Status
                    </vs-divider>
                    <div class="vx-row mb-6" v-if="destApproverLines.length">
                        <table class="m-3 vs-table vs-table--tbody-table table-bordered" width="100%">
                            <thead class="vs-table--thead">
                                <tr>
                                    <th>Approver</th>
                                    <th>Status</th>
                                    <th>Inbox Time</th>
                                    <th>Action Time</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tr, index) in destApproverLines" :key="index" style="padding-bottom: 5px">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.approver }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.status }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.inbox_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.action_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.reason }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="flex md:flex-row">
            <div class="w-full">
                <h4>Relocation Items</h4>
                <hr>
                <div class="vx-row pt-2"></div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="width: 33%;">SKU</th>
                            <th style="width: 29%;">Batch / Exp. Date</th>
                            <th style="width: 7%;">Moved Qty</th>
                            <th style="width: 12%;">HU</th>
                            <th style="width: 7%;">Amount UOM</th>
                            <th style="width: 7%;">Total Qty</th>
                            <th style="width: 5%;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="skuLine.sku_label" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="skuLine.batch" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" type="number" v-model="skuLine.qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="skuLine.unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" type="number" v-model="skuLine.qty_uom" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" type="text" v-model="skuLine.formated_total_qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-checkbox
                                    :disabled="true"
                                    v-model="skuLine.is_handover"
                                ></vs-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    components: {
        VueEasyLightbox,
    },
    data() {
        return {
            id: null,
            readonly: true,
            code: null,
            handoverCode: null,
            selectedType: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            selectedImposition: null,
            driver: null,
            vehicle: null,
            reqDeliveryDate: null,
            eta: null,
            handoverDate: null,
            notes: null,
            shipmentCost: null,
            costRatio: 0,
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    sku_label: null,
                    unit: null,
                    qty: 0,
                    batch: null,
                    qty_uom: 0,
                    total_qty: 0,
                    formated_total_qty: null,
                    is_handover: false,
                }
            ],
            srcApproverLines: [
                {
                    approver: null,
                    status: null,
                    inbox_time: null,
                    action_time: null,
                    reason: null,
                }
            ],
            destApproverLines: [
                {
                    approver: null,
                    status: null,
                    inbox_time: null,
                    action_time: null,
                    reason: null,
                }
            ],

            // attachments section
            file: null,
            fileUrl: '',
            attachment: "",
            fileAttachment: [],
            IDAttachment: [],
            visible: false,
            images: [],
            index: 0,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        handleUpdateFormat(val) {
            let cleanValue = val.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
        handleUpdateCurrency(val) {
            let cleanValue = val.toString().replace(/\D/g, '');
            let formattedValue = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 2
            }).format(cleanValue);

            return formattedValue;
        },
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/outbound/sto-processing/detail-complete/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.code = resp.data.code
                    this.handoverCode = resp.data.handover_code
                    this.selectedType = resp.data.sto_type
                    this.selectedSrcWarehouse = resp.data.selected_src_warehouse
                    this.selectedDestWarehouse = resp.data.selected_dest_warehouse
                    this.selectedImposition = resp.data.imposition
                    this.driver = resp.data.driver
                    this.vehicle = resp.data.vehicle
                    this.reqDeliveryDate = resp.data.delivery_date
                    this.eta = resp.data.eta
                    this.handoverDate = resp.data.handover_date
                    this.notes = resp.data.notes
                    this.shipmentCost = this.handleUpdateCurrency(resp.data.shipment_cost)
                    this.costRatio = resp.data.cost_ratio
                    this.srcApproverLines = resp.data.src_approver_reason;
                    this.destApproverLines = resp.data.dest_approver_reason;
                    this.skuLines = resp.data.sku_lines
                    this.skuLines.forEach(val => {
                        val.formated_total_qty = this.handleUpdateFormat(val.total_qty)
                    })
                    if (resp.data.attachment) {
                        resp.data.attachment.forEach((val) => {
                            this.fileAttachment.push({
                                id: val.id,
                                NameFile: val.file_name,
                                PathFile: val.file_url,
                            });
                            this.images.push(val.file_url);
                        });
                    }
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleBack() {
            this.$router.push({
                name: "outbound.stock-transfer-order-processing",
                params: {status: 1},
            });
        },

        // attachments section
        download(tr) {
            const link = document.createElement('a');
            link.href = this.urlFile(tr);
            link.download = tr.NameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onChangeAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    const fileIdx = this.$refs.file.files[i]
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.images.push(URL.createObjectURL(fileIdx));
                }
                document.querySelector("#fileInput").value = "";
            }
        },
        openPreview(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
    },
    watch: {
        // ...
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
        urlFile: () => {
            return (tr) => {
                return tr.File instanceof Blob ? URL.createObjectURL(tr.File) : tr.PathFile
            }
        }
    },
}
</script>